import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import { makeStyles, Typography, Card } from '@material-ui/core'
import { useMutation, useQuery } from 'react-query'
import useQueryParams from '../../../hooks/useQueryParams'
import QueryKeys from '../../../utils/constants/queryKeys'
import billingAddonUsersSelctionStyles from './billingAddonUsersSelection.styles'
import getOrgUsers from '../../../Apis/organisation/getOrgUsers'
import getOrgPendingInvitations from '../../../Apis/invitations/getOrgPendingInvitations'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import { useTranslate } from 'react-admin'
import MyTable from '../../../components/DynamicTable/MyTable/MyTable'
import TypographyField from '../../../components/DynamicTable/Fields/TypographyField/TypographyField'
import localStorageIds from '../../../utils/constants/localStorageIds'
import getUserData from '../../../Apis/user/getUserData'
import decodeJwt from 'jwt-decode'

const BillingAddonUsersSelection = ({
  billingAddon,
  usersToInvite,
  stepForward,
  setUsersToInvite,
}) => {
  const useStyles = makeStyles(billingAddonUsersSelctionStyles)
  const translate = useTranslate()
  const token = decodeJwt(localStorage.getItem(localStorageIds.TOKEN))
  const userId = token && token.sub ? token.sub : null
  const classes = useStyles()
  const queryParams = useQueryParams()
  const { org } = queryParams
  const [orgUsers, setOrgUsers] = useState([])

  const profileData = useQuery([QueryKeys.GET_ACCOUNT_ME], () => getUserData())

  const orgOwnerInvitationsQuery = useQuery(
    [QueryKeys.GET_ORG_PENDING_INVITATIONS],
    () => getOrgPendingInvitations({ orgId: org }),
    {
      onSuccess: (response) => {
        orgUsersMutation.mutate({ invitations: response })
      },
    },
  )

  const orgUsersMutation = useMutation([QueryKeys.GET_ORG_USERS], () => getOrgUsers(org), {
    onSuccess: (response, { invitations }) => {
      const parsedInvitations = invitations.map((invUser) => {
        const isAdmin = Object.values(invUser.roles).includes('Admin')

        return {
          ...invUser,
          email: invUser.receiverEmail,
          orgRole: isAdmin ? 'Admin' : 'Member',
          name: invUser?.firstName ? invUser?.firstName.concat(' ', invUser.lastName) : '',
        }
      })

      const parsedOrgUsers = response.map((orgUser) => {
        const isAdmin = Object.values(orgUser.roles).includes('Admin')
        const isOwner = Object.values(orgUser.roles).includes('Owner')

        return {
          ...orgUser,
          orgRole: isOwner ? 'Owner' : isAdmin ? 'Admin' : 'Member',
          name: orgUser?.firstName ? orgUser?.firstName.concat(' ', orgUser.lastName) : '',
        }
      })

      if (parsedInvitations.length === 0 && parsedOrgUsers.length === 0) {
        const isAdmin = Object.values(profileData?.data.orgRoles).includes('Admin')
        const isOwner = Object.values(profileData?.data.orgRoles).includes('Owner')

        // Add the current user if no invitations or users are found
        setOrgUsers([
          {
            id: userId,
            firstName: profileData?.data.firstName,
            lastName: profileData?.data.lastName,
            email: profileData?.data.email,
            orgRole: 'Owner',
            name: `${profileData?.data.firstName} ${profileData?.data.lastName}`,
            roles: {},
            scopes: [],
            isAdmin: isAdmin,
            isOwner: isOwner,
            status: 'inactive',
          },
        ])
      } else {
        setOrgUsers([...parsedInvitations, ...parsedOrgUsers])
      }
    },
  })

  useEffect(() => {
    if (orgUsers.length > 0 && userId && Object.keys(usersToInvite).length === 0) {
      const currentUser = orgUsers.find((user) => user.id === userId)
      if (currentUser) {
        setUsersToInvite([currentUser])
      }
    }
  }, [orgUsers, userId])

  return (
    <div>
      {!billingAddon.isOrgLevel ? (
        orgUsersMutation?.isLoading || orgOwnerInvitationsQuery?.isLoading ? (
          <LoaderDots className={classes.loaderIcon} />
        ) : (
          <>
            <div style={{ width: '99%', margin: 'auto', overflowY: 'auto' }}>
              <Typography variant='h6' className={classes.warningMessage}>
                {translate('ra.text.addonInfo')}
              </Typography>{' '}
              <MyTable
                rows={orgUsers}
                setUpperSelectState={setUsersToInvite}
                tableStyles={{ overflow: 'auto' }}
                hasHeader
                hasDivider
                size='small'
                boldHeader
                hasCheckbox
                allowMultipleChecked
                checkBoxStyle='square'
                startingSelection={usersToInvite}
                disabledRowId={userId}
              >
                <TypographyField title='Name' key='name' colorChoice='initial' />
                <TypographyField title='email' key='email' colorChoice='initial' />
                <TypographyField title='Role' key='orgRole' colorChoice='initial' />
              </MyTable>
            </div>
            <div className={classes.buttonContainer}>
              <ButtonWithLoader
                label={translate('ra.buttons.next')}
                justifyContent='flex-end'
                onClick={stepForward}
                disabled={Object.keys(usersToInvite).length === 0}
              />
            </div>
          </>
        )
      ) : (
        <div
          style={{
            width: '99%',
            margin: 'auto',
            overflowY: 'auto',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Card variant='outlined' elevation={0} className={classes.addonCard}>
            <Typography variant='h6' gutterBottom>
              {billingAddon.id}
            </Typography>
            <Typography variant='body1'>{translate('ra.text.socialDes')}</Typography>
          </Card>

          <div className={classes.buttonContainer}>
            <ButtonWithLoader
              label={translate('ra.buttons.next')}
              justifyContent='flex-end'
              onClick={stepForward}
              disabled={Object.keys(usersToInvite).length === 0}
            />
          </div>
        </div>
      )}
    </div>
  )
}

BillingAddonUsersSelection.propTypes = {
  usersToInvite: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
      roles: PropTypes.object.isRequired,
      scopes: PropTypes.arrayOf(PropTypes.string).isRequired,
      orgRole: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isAdmin: PropTypes.bool,
      isOwner: PropTypes.bool,
      status: PropTypes.string,
      receiverEmail: PropTypes.string,
    }),
  ).isRequired,
  billingAddon: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pricing: PropTypes.string.isRequired,
    seats: PropTypes.number,
    enabled: PropTypes.bool.isRequired,
    userActivatable: PropTypes.bool.isRequired,
    pricingModel: PropTypes.string,
    unitPrice: PropTypes.number,
    isOrgLevel: PropTypes.bool,
  }).isRequired,
  stepForward: PropTypes.func,
  setUsersToInvite: PropTypes.func,
}

export default BillingAddonUsersSelection
